import React from 'react';
import './Pricing.css';
import { useNavigate } from 'react-router-dom';

const pricingData = [
  {
    title: 'Data Vizualization & Analytics',
    path: '/services/data-analytics'
  },
  {
    title: 'Consulting Services',
    path: '/services/consulting'
  },
  {
    title: 'Project Management',
    path: '/services/project-management'
  },
  {
    title: 'Energy Services',
    path: '/services/energy-services'
  },
  {
    title: 'Construction',
    path: '/services/construction'
  },
  {
    title: 'Property Management',
    path: '/services/property-management'
  }
];

const handleEmailButtonClick = () => {
  window.location.href = 'mailto:codelux.outreach@gmail.com?subject=Small%20Business%20Inquiry';
};

const Pricing = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="pricing-container">
      <div className="pricing-section">
        <h1 className="pricing-heading">Pricing</h1>
        <h3 className="pricing-description">
          Pricing is determined by the scope of the service provided, the total estimated time, and the category of service. Any project over 20 hours starts at a minimum of $5,000 with an hourly rate used to determine the estimated end rate. Below is a list of our service lines. Click on an item to view its respective page.
        </h3>
        <div className="pricing-buttons">
          {pricingData.map((item, index) => (
            <button key={index} className="pricing-button" onClick={() => navigateTo(item.path)}>
              {item.title}
            </button>
          ))}
        </div>
      </div>
      <div className="small-business-section">
        <h1 className="small-business-heading">Small Business and Small Project Pricing</h1>
        <h3 className="small-business-description">
          We understand that small businesses cannot always afford customized software. Our pricing is designed to be flexible and accommodating to fit various budgets. We work closely with you to tailor our services to meet your needs and provide the best value for your investment. Whether you're looking to start a new project or enhance an existing one, we're here to help you achieve your goals without breaking the bank.
        </h3>
        <h3 className="small-business-description">
          For small businessess and/or projects that are under 20 hours, we offer our Small Project Pricing which starts at a minimum of $1,500.00 for all projects under 20 hours. If a project exceeds 20 hours, then the rest would fall under our hourly rate.
        </h3>
        <h3 className="small-business-description">
          If you are a small business looking for software solutions, contact us to see how we can work with your business and your budget.
        </h3>

        <div className='cta'>
          <button onClick={handleEmailButtonClick}>Contact Us</button>
      </div>
      </div>
    </div>
  );
};

export default Pricing;
